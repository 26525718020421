import React from "react";
import ReactDOM from "react-dom";
import 'fontsource-roboto';
import "./index.css";
// import 
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";

ReactDOM.render(
        <App />,
    document.getElementById("root")
    );
registerServiceWorker();
