import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import "./App.css";
import ApiFetching from "./components/ApiFetching/ApiFetching";
import PubList from "./components/PubList/PubList";
import PubTable from "./components/PubTable/PubTable";
import CitationViz from "./components/CitationViz/CitationViz";
import Link from "@material-ui/core/Link";

const App = () => {
  // setting the states and default values

  const preventDefault = (event) => event.preventDefault();

  const [inputMessage, setInputMessage] = useState("");

  const [maxNumber, setMaxNumber] = useState("");

  const [queryInput, setQueryInput] = useState("");
  const [queryOutput, setQueryOutput] = useState("");
  const [querySet, setQuerySet] = useState(false);

  const [ownCache, setOwnCache] = useState("");
  const [loopEnd, setLoopEnd] = useState(false);

  const [search_query, setSearchQuery] = useState(``);

  const [selectedPostId, setSelectedPostId] = useState(null);
  const [selectedAuthor, setSelectedAuthor] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  const [total, setTotal] = useState("");

  const resetList = () => {
    // get all 'li' elements
    const liElem = document.getElementsByTagName("li");
    // console.log(liElem);
    for (let i = 0; i < liElem.length; i++) {
      // Remove the class 'active' if it exists
      liElem[i].classList.remove("active");
    }
    // get all 'li .auth' elements
    const authElem = document.getElementsByClassName("auth");
    // console.log(liElem);
    for (let i = 0; i < authElem.length; i++) {
      // Remove the class 'active' if it exists
      authElem[i].classList.remove("active");
    }

    //remove filtered class on tabs
    if (document.querySelector(".MuiButtonBase-root#filtered")) {
      document
        .querySelector(".MuiButtonBase-root#filtered")
        .setAttribute("id", "");
    }
  };

  // prepare metadata transforming within setSelectedPostId & setSelectedAuthor
  const createData = (key, value) => {
    return { key, value };
  };
  const metadataRows = (ownCache, doi) => {
    let rows = [];

    // console.log("ownCache-Check:", ownCache);
    ownCache.citListPerDoi.map((entry) => {
      if (entry[0].name === doi) {
        const data = entry[0];

        // console.log("data", data);

        rows.push(createData("Titel", data.title));

        if (data.author) {
          const creatorLength = data.author.length;
          let i = 0;

          rows.push(
            createData(
              "Autor(en)",
              data.author.map((auth) => {
                if (creatorLength === i + 1) {
                  return auth.name;
                } else {
                  i = i + 1;
                  return auth.name + ", ";
                }
              })
            )
          );
        }

        rows.push(
          createData("DOI", data.name),
          createData("Datenquelle", data.source)
        );

        // console.log("rows", rows);
        setSelectedRow(rows);
      }
    });
  };

  // highlight focused list items
  const setActiveListItem = (ownCache, doi) => {
    // console.log("setActiveListItem(doi)-Check:", doi);
    setSelectedPostId(doi);
    setSelectedAuthor(null);

    // create metaData rows
    metadataRows(ownCache, doi);

    // loop through all '.title' elements and remove active-Class
    resetList();
    // add 'active' classs to the element that was clicked
    document.getElementById(doi).classList.add("active");

    //select Tab and add class to visualize this filter
    document
      .querySelector(".MuiButtonBase-root:nth-child(2)")
      .setAttribute("id", "filtered");

    // console.log("clicked on: ", doi);
  };

  const setActiveAuthor = (ownCache, auth, doi) => {
    setSelectedPostId(null);
    setSelectedAuthor(auth);
    metadataRows(ownCache, doi);
    // loop through all '.title' elements and remove active-Class
    resetList();
    // add 'active' classs to the element that was clicked
    document.getElementById(auth).classList.add("active");

    //select Tab and add class to visualize this filter
    document
      .querySelector(".MuiButtonBase-root:nth-child(1)")
      .setAttribute("id", "filtered");

    // console.log("clicked on: ", auth);
  };

  // if no search query has been given yet
  if (!querySet) {
    //if a search query is given - checking the query input
    const queryCheck = (queryInput) => {
      if (queryInput.length > 0) {
        setQueryOutput(queryInput);

        //check special characters
        const format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        if (format.test(queryInput)) {
          // setInputMessage("Bitte keine Sonderzeichen verwenden.");
          alert("Bitte keine Sonderzeichen verwenden.");
        } else if (queryInput.includes(" ")) {
          // check if query contains spaces, replace them (and put query to quotes) but keep original string for Output - "Helpful Hints" at https://dev.springernature.com/adding-constraints
          setSearchQuery(queryInput.replace(" ", "%20"));
          setQuerySet(true);
        } else {
          setSearchQuery(queryInput);
          setQuerySet(true);
        }
      }
    };

    const handleSubmit = (e) => queryCheck(queryInput);

    // return Start page

    return (
      <div className="App searchView">
        <div className="header">
          <Typography variant="h3" component="h1" color="primary">
            SciGraphViz
          </Typography>
        </div>
        <div className="sub">
          <Typography color="secondary" variant="h5" component="h2">
            Wer hat wen zitiert?
          </Typography>
        </div>
        <div className="sub">
          <Typography variant="subtitle1">
            Entdecken Sie welche Autoren in welchen Publikationen des{" "}
            <Link
              href="https://www.springernature.com/gp/researchers/scigraph"
              target="_blank"
              rel="noopener"
            >
              Springer Nature SciGraph
            </Link>{" "}
            referenziert werden.
          </Typography>
        </div>

        <form
          className="form"
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <TextField
            onChange={(e) => setQueryInput(e.target.value)}
            label="Suchbegriff"
            variant="outlined"
            size="small"
          />
          <Button
            type="submit"
            color="primary"
            variant="contained"
            size="normal"
          >
            Suchen
          </Button>
        </form>
      </div>
    );
  }

  // if search query is given or the ApiFetching-loop isn't over yet: send needed data to the ApiFetching component

  if (querySet && !loopEnd) {
    return (
      <div>
        <ApiFetching
          maxNumber={(e) => setMaxNumber(e)}
          search_query={search_query}
          queryOutput={queryOutput}
          total={(e) => {
            setTotal(e);
          }}
          loopEnd={() => setLoopEnd(true)}
          ownCache={(e) => setOwnCache(e)}
          setActiveListItem={(ownCache, doi) => {
            setOwnCache(ownCache);
            setActiveListItem(ownCache, doi);
          }}
          setActiveAuthor={(ownCache, authorName, doi) => {
            setOwnCache(ownCache);
            setActiveAuthor(ownCache, authorName, doi);
          }}
        />
      </div>
    );

    // else: if ApiFetching ended its loop - show the result page with the component layout
  } else if (loopEnd) {
    return (
      <div className="App">
        <div className="left">
          <PubList
            total={total}
            maxNumber={maxNumber}
            query={queryOutput}
            items={ownCache.list}
          />
          <PubTable rows={selectedRow} />
        </div>
        <CitationViz
          citListPerDoi={ownCache.citListPerDoi}
          citListPerAuthor={ownCache.citListPerAuthor}
          fullListPerDoi={ownCache.fullListPerDoi}
          fullListPerAuthor={ownCache.fullListPerAuthor}
          id={selectedPostId}
          author={selectedAuthor}
          //getting "reset"-state from this child compoment
          resetId={() => {
            setSelectedAuthor(null);
            setSelectedPostId(null);
            // loop through all '.title' elements and remove active-Class
            resetList();
            setSelectedRow(null);
          }}
        />
      </div>
    );
  }
};

export default App;
