import React from "react";
import Typography from "@material-ui/core/Typography";
import "./PubList.css";
import List from "@material-ui/core/List";

const PubList = (props) => {
  // get data from App.js

  const listLength = () => {
    if (props.maxNumber === 1) {
      return <p>Auszug des {props.startPos}. Eintrags:</p>;
    } else return <p>Auszug der ersten {props.maxNumber} Ergebnisse:</p>;
  };

  // Result List to render
  let PubList = (
    <div className="pubList">
      <Typography variant="h3" component="h1" color="primary">
        SciGraphViz
      </Typography>
      <p>
        Es gibt insgesamt {props.total} Ergebnisse für <b>{props.query}</b>.
      </p>
      {listLength()}

      <List>{props.items}</List>
    </div>
  );
  return PubList;
};

export default PubList;
