import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import d3hierarchical from "./d3hierarchical.js";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
// import RotateLeftIcon from "@material-ui/icons/RotateLeft";

import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import "./CitationViz.css";

const CitationViz = (props) => {
  // get data from App.js

  // set "Ref" for external D3-Library-embedding
  const myRef = useRef(null);
  const [pubTitle, setPubTitle] = useState("");

  // List-Style: Standard perDoi, Alternative: perAuthor
  const [listStyle, setListSyle] = useState("Autor");

  useEffect(() => {
    let fullList = "";
    // Select list using listStyle
    listStyle === "Titel"
      ? (fullList = props.fullListPerDoi)
      : (fullList = props.fullListPerAuthor);

    // if post selected or only one result available (citListPerPoi-lenght=2 because additional object-info in citList - not perAuthor, because more than 1 author could exist!)
    let d3data = [];

    // if title selected
    if (props.id) {
      let id = "";

      id = props.id;

      props.citListPerDoi.map((item) => {
        if (item[0].name === id) {
          d3data = item;
          setTabValue(1);
          //get title by doi
          setPubTitle(
            <span>
              Zitationen in <i>{item[0].title}</i>
            </span>
          );
        }
      });
      //d3-part
      d3hierarchical(d3data, myRef);

      //else if author selected
    } else if (props.author) {
      setPubTitle(
        <span>
          Zitationen von <i>{props.author}</i>
        </span>
      );

      props.citListPerAuthor.map((item) => {
        if (item[0].name && item[0].name === props.author) {
          d3data = item;
          setTabValue(0);
        }
      });

      d3hierarchical(d3data, myRef);
    } else {
      d3hierarchical(fullList, myRef);
      setPubTitle(
        <span className="all">
          Zitationen des Ergebnisauszugs, gruppiert nach {listStyle}
        </span>
      );
    }
  }, [props.id, props.author, listStyle]);

  let CitationViz;

  // handling the tab bar
  const [tabValue, setTabValue] = useState(0);
  const handleTabChange = (event, newTabValue) => {
    setTabValue(newTabValue);
    props.resetId();
  };

  CitationViz = (
    <div className="CitationViz">
      <AppBar position="static">
        <Typography className="tabTitle">Visualisierung</Typography>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="simple tabs example"
        >
          <Tab
            label="Autor"
            onClick={() => {
              setListSyle("Autor");
            }}
          />
          <Tab
            label="Titel"
            onClick={() => {
              setListSyle("Titel");
            }}
          />
        </Tabs>
      </AppBar>
      <p className="pubTitle">{pubTitle}</p>
      <p className="d3render" ref={myRef}></p>
    </div>
  );

  return CitationViz;
};

export default CitationViz;
