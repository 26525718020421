import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import "./ApiFetching.css";
import axios from "axios";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";

// insert own Springer Nature API Key here
const apikey = `93543c33b297e3073f1270e54a8ea45b`;

// set number of results to show
const maxNumber = 3;

// set starting point of results to show
let startAt = 1;

// own main cache for the fetched data and the visualizations
let ownCache = [];

ownCache.list = []; // the own HTML list elements for results will be put in here (id=actualPos)
ownCache.citListPerDoi = []; // the citation data per DOI will be put in here (depends on actualPos)
ownCache.citListPerAuthor = []; // the citation data per author will be put in here

let actualPos = 1;

// check if an element exists in array using a comparer function
// comparer : function(currentElement)
Array.prototype.inArray = function (comparer) {
  for (var i = 0; i < this.length; i++) {
    if (comparer(this[i])) return true;
  }
  return false;
};

// adds an element to the array if it does not already exist using a comparer function
Array.prototype.pushIfNotExist = function (element, comparer) {
  if (!this.inArray(comparer)) {
    this.push(element);
  }
};

// the main functions for this component, got data from App.js (props)
const ApiFetching = (props) => {
  props.maxNumber(maxNumber);
  let search_query = props.search_query;

  const number = `1`;
  const format = `jsonld`;

  const [sciFetch, setSciFetch] = useState(
    `https://api.springernature.com/meta/v2/${format}?q=${search_query}&api_key=${apikey}&p=${number}&s=${startAt}`
  ); //backticks for template-strings: direct var-usage, linebreaks

  // function to check if the loop should go on or it came to an end and set the new fetching URL - triggered at the end of an iteration
  const triggerNextItem = () => {
    ownCache.list.push(itemList);
    if (actualPos < maxNumber) {
      console.log("next");
      startAt++;
      actualPos++;
      setIsLoaded(false);
      setSciFetch(
        `https://api.springernature.com/meta/v2/${format}?q=${search_query}&api_key=${apikey}&p=${number}&s=${startAt}`
      );
      setFetchUrl(
        `https://api.springernature.com/meta/v2/${format}?q=${search_query}&api_key=${apikey}&p=${number}&s=${startAt}`
      );
      setFetchTryNr(fetchTryNr + 1);
    } else setLoopEnd(true);
  };

  const [items, setItems] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [loopEnd, setLoopEnd] = useState(false);

  const [fetchUrl, setFetchUrl] = useState(sciFetch);
  const [crossFetch, setCrossFetch] = useState("");

  const [fetchTryNr, setFetchTryNr] = useState(1); // Trigger for useEffect

  let itemList = ""; // cache for an single result

  // React Hook for iterative Fetch, depends on actualPos & maxNumber

  useEffect(() => {
    console.log("fetchTryNr:", fetchTryNr, "actualPos:", actualPos);
    console.log("fetchUrl ", fetchUrl);
    axios.get(fetchUrl).then((response) => {
      console.log("got data from", fetchUrl, ":", response.data);
      console.log("sciFetch", sciFetch);
      if (fetchUrl === sciFetch) {
        console.log("Fetched (unknown) SciGraph:", response.data);
        response.data.mySource = "scigraph";

        //Remember total number of results from SciGraph result
        props.total(response.data.result[0].total);

        if (
          (response.data.records &&
            typeof response.data.records[0] === "string") ||
          (response.data.records && response.data.records[0].jsonld === false)
        ) {
          // if empty SciGraph entry (Iterate-Trigger)
          if (response.data.records[0].jsonld === false) {
            const pubUrl = response.data.records[0].uri;
            const doiFetch = pubUrl.replace(
              "https://scigraph.springernature.com/pub.",
              ""
            );

            //   Crossref-Fetching because missing jsonld data in SciGraph - DOI: ${doiFetch}
            setCrossFetch(
              `https://api.crossref.org/works?filter=doi:${doiFetch}`
            );
            setFetchUrl(
              `https://api.crossref.org/works?filter=doi:${doiFetch}`
            );
          }
          // if API error message: fetch again (Iterate-Trigger)
          else if (typeof response.data.records[0] === "string") {
            console.log(
              '%c Neuversuch aufgrund fehlerhafter API-Abfrage: "There was a problem retrieving the results from SciGraph."',
              "background: #2b0001; color: #fa787a"
            );
          }
          setFetchTryNr(fetchTryNr + 1);
          setIsLoaded(false);
        } else if (response.data.records[0].jsonld.citation) {
          //specific crossref-fetching for info of citated sources from this scigraph-entry
          console.log(
            "specific crossref-fetching for info of citated sources from this scigraph-entry"
          );

          // collect all citated DOIs
          let citDoiCollect = [];
          response.data.records[0].jsonld.citation.map((cit) => {
            // check how id is formed
            if (
              cit.id.includes("sg:pub") ||
              cit.id.includes("https://doi.org/")
            ) {
              const citDoi = cit.id
                .replace("sg:pub.", "doi:")
                .replace("https://doi.org/", "doi:");

              //push to collection
              citDoiCollect.pushIfNotExist(citDoi, (e) => {
                return e === citDoi;
              });
            } else
              console.log(
                "citation source",
                cit.id,
                "contains no identifiable DOI"
              );
          });

          // citDoiCollect.length < 1000 <-- 1000 is the maximum result number to fetch
          console.log(
            "less than 1000 citations in this SciGraph entry:",
            citDoiCollect.length
          );
          console.log(
            "there are more than 1000 citations in this SciGraph entry but only 1000 are being fetched now:",
            citDoiCollect.length
          );

          // put all collected DOIs together to one string
          const citFetch =
            "https://api.crossref.org/works?filter=" +
            citDoiCollect.join(",") +
            "&select=DOI,title,author&rows=1000";

          //fetching those citation sources
          axios.get(citFetch).then((citResponse) => {
            // console.log("citResponse", citResponse);
            //put fetched data to previous scigraph response where DOIs match
            citResponse.data.message.items.map((citFetched) => {
              console.log(citFetched);
              response.data.records[0].jsonld.citation.map((cit) => {
                cit.id.includes(citFetched.DOI)
                  ? (cit.DOI = citFetched.DOI)
                  : null;
                cit.id.includes(citFetched.title)
                  ? (cit.title = citFetched.title[0])
                  : null;
                cit.id.includes(citFetched.author)
                  ? (cit.author = citFetched.author)
                  : null;
              });
            });

            // finally go to the next item
            setItems(response.data);
            setIsLoaded(true);
          });
        } else {
          setItems(response.data);
          setIsLoaded(true);
        }
      } else if (fetchUrl === crossFetch) {
        response.data.mySource = "crossref";
        // console.log(response.data);
        setItems(response.data);
        setIsLoaded(true);
      } else
        console.log(
          "no suitable URL to fetch given: " + fetchUrl + " VS. " + crossFetch
        );
    });
  }, [fetchTryNr]); // empty array as the 2nd argument to only run on mount and unmount, thus stopping any infinite loops

  //if the API fetching process started, show the loading screen
  if (!isLoaded && !loopEnd) {
    return (
      <div className="App loader">
        <div className="loadInfo">
          <div>
            <Typography component="span" variant="h5" color="primary">
              Springer Nature SciGraph
            </Typography>{" "}
            <Typography component="span" variant="h5">
              durchsuchen nach <b>{props.queryOutput}</b>:{" "}
              {Math.round(((actualPos - 1) / maxNumber) * 100)} %
            </Typography>
          </div>
          <div className="lds-grid">
            {/* Div-Elements for the Loading Spinner */}
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    );
  } else if (isLoaded && !loopEnd) {
    // loop through results, save them properly and create result list items
    if (items.mySource === "crossref") {
      const item = items.message.items[0];
      const doi = item.DOI;
      if (item.author) {
        const creatorLength = item.author.length;
        itemList = (
          <ListItem key={actualPos} id={doi} data-id={doi} className="crossref">
            <ListItemText>
              <div
                className="title"
                onClick={() => {
                  props.setActiveListItem(ownCache, doi);
                }}
              >
                {" "}
                {item.title[0]}{" "}
              </div>
              <div className="authors">
                {item.author.map((creatorItem, i) => {
                  const authorName =
                    creatorItem.given + " " + creatorItem.family;
                  if (creatorLength === i + 1) {
                    return (
                      <span
                        key={creatorItem.id}
                        className="auth"
                        id={authorName}
                        onClick={() => {
                          props.setActiveAuthor(ownCache, authorName, doi);
                        }}
                      >
                        {authorName}
                      </span>
                    );
                  } else {
                    return (
                      <span
                        key={creatorItem.id}
                        className="auth"
                        id={authorName}
                        onClick={() => {
                          props.setActiveAuthor(ownCache, authorName, doi);
                        }}
                      >
                        {authorName}
                        {"; "}{" "}
                      </span>
                    );
                  }
                })}
              </div>
            </ListItemText>
          </ListItem>
        );

        // Collect CrossRef citation data (per author) and prepare for D3

        //if citation data available...
        if (item.reference) {
          // Add author(s) as object(s) directly in citListPerAuthor
          let i = ownCache.citListPerAuthor.length;
          item.author.map((creatorItem) => {
            let citEntryAuthor = [];
            // new author object, without any given prototype ("{}")
            citEntryAuthor.push(
              Object.create(
                {},
                {
                  // mapping authors in d3 via author-name itself
                  name: {
                    value: creatorItem.given + " " + creatorItem.family,
                  },
                  source: { value: "Crossref" },
                  imports: { value: [] },
                }
              )
            );

            // push the given citated sources to "imports"
            item.reference.map((cit) => {
              // if Author available use this...
              if (cit.author) {
                const citAuthor = cit.author;
                citEntryAuthor[0].imports.push(citAuthor);
                // + own entry per citation, if not already existing
                const citSource = Object.create(
                  {},
                  { name: { value: citAuthor } }
                );
                citEntryAuthor.pushIfNotExist(citSource, (e) => {
                  return e.name === citSource.name;
                }); // author imported
              } else {
                console.log("no author name in Crossref-Reference");
              }
            });

            ownCache.citListPerAuthor[i] = citEntryAuthor;

            i++;
          });

          // Put citation data in the cache (to the current position)
        } // No citation/reference data available for position
        else
          console.log(
            `keine Zitations-/Referenz-Daten verfügbar für Position ${actualPos}`
          );
      } // no author data available: just show title
      else {
        itemList = (
          <ListItem key={actualPos} id={doi} data-id={doi} className="crossref">
            <ListItemText>
              <div
                className="title"
                onClick={() => {
                  props.setActiveListItem(doi);
                }}
              >
                {item.title[0]}
              </div>
            </ListItemText>
          </ListItem>
        );
      }

      // Collect CrossRef citation data (per DOI) and prepare for D3

      //if citation data available...
      if (item.reference) {
        //if authors available push them too for metadata
        let citEntryAuthor = [];

        if (item.author) {
          item.author.map((creatorItem) => {
            // new author object, without any given prototype ("{}")
            citEntryAuthor.push(
              Object.create(
                {},
                {
                  name: {
                    value: creatorItem.given + " " + creatorItem.family,
                  },
                }
              )
            );
          });
        }

        let citEntryTitle = [
          {
            title: item.title[0],
            name: item.DOI,
            source: "Crossref",
            author: citEntryAuthor,
            imports: [],
          },
        ];

        // push their infos to complete the graph
        item.reference.map((cit) => {
          // if DOI available
          if (cit.DOI) {
            const citDoi = cit.DOI;
            citEntryTitle[0].imports.push(citDoi);

            // if "unstructured" available
            if (cit.unstructured) {
              // ********** This code block has been switched off because checking unstructured data automated for specific information didn't work out for all crossref publications - put the experiments could become handy some day

              // // set regex cases for getting title from string
              // // all title cases end before a dot "."
              // const reBracketDot = /.+?\)\.\s+(.+?(?=\.)).*/; // title after ")."
              // const reDotColon = /.+?\.:\s+(.+?(?=\.)).*/; // title after ".:"
              // const reBracket = /.+?\)\s+(.+?(?=\.)).*/; // title after ")"
              // const reDot = /.+?\.\s+(.+?(?=\.)).*/; // title after "."

              // // create title variable
              // let citTitle = "";

              // // check which kind of unstructured string it is to get title properly
              // switch (true) {
              //   case reBracketDot.test(cit.unstructured):
              //   console.log("reBracketDot on:",cit.unstructured);
              //   console.log("regex fits");
              //     citTitle = cit.unstructured.replace(reBracketDot, "$1");
              //     break;
              //   case reDotColon.test(cit.unstructured):
              //   console.log("reDotColon on:",cit.unstructured);
              //   console.log("regex fits");
              //     citTitle = cit.unstructured.replace(reDotColon, "$1");
              //     break;
              //   case reBracket.test(cit.unstructured):
              //   console.log("reBracket on:",cit.unstructured);
              //   console.log("regex fits");
              //     citTitle = cit.unstructured.replace(reBracket, "$1");
              //     break;
              // case reDot.test(cit.unstructured):
              // console.log("reDot on:",cit.unstructured);
              // console.log("regex fits");
              //   citTitle = cit.unstructured.replace(reDot, "$1");
              //   break;
              // default:
              //   // show complete unstructured entry if nothing fits
              // console.log("no regex fit on:", cit.unstructured);
              //   citTitle = cit.unstructured;
              // }

              // + own entry per citation
              citEntryTitle.push({
                name: citDoi,
                title: cit.unstructured,
                imports: [],
              });
            } else {
              // + own entry per citation, only DOI
              citEntryTitle.push({ name: citDoi, imports: [] });
            }
          }
          // .. else: no entry!"
        });

        // Put citation data in the cache (to the current position)
        ownCache.citListPerDoi[actualPos] = citEntryTitle;
      } else {
        // No citation/reference data available for position

        console.log(
          `keine Zitations-/Referenz-Daten verfügbar für Position ${actualPos}`
        );
      }

      // Iterate-Trigger: Cache entry, count up and re-fetch if not yet at the end - otherwise: stop loop and render
      triggerNextItem();
    } else if (items.mySource === "scigraph") {
      // same procedure as above but instead of crossref for scigraph
      // loop through results, save them properly and create result list items
      const item = items.records[0];
      const creatorLength = item.jsonld.author.length;
      const doi = item.jsonld.id.replace("sg:pub.", "");
      itemList = (
        <ListItem key={actualPos} id={doi} data-id={doi} className="scigraph">
          <ListItemText>
            <div
              className="title"
              onClick={() => {
                props.setActiveListItem(doi);
              }}
            >
              {item.jsonld.name}
            </div>
            <div className="authors">
              {item.jsonld.author.map((creatorItem, i) => {
                const authorName =
                  creatorItem.givenName + " " + creatorItem.familyName;
                if (creatorLength === i + 1) {
                  return (
                    <span
                      key={creatorItem.id}
                      className="auth"
                      id={authorName}
                      onClick={() => {
                        props.setActiveAuthor(ownCache, authorName, doi);
                      }}
                    >
                      {authorName}
                    </span>
                  );
                } else {
                  return (
                    <span
                      key={creatorItem.id}
                      className="auth"
                      id={authorName}
                      onClick={() => {
                        props.setActiveAuthor(ownCache, authorName, doi);
                      }}
                    >
                      {authorName}
                      {"; "}{" "}
                    </span>
                  );
                }
              })}
            </div>
          </ListItemText>
        </ListItem>
      );

      // Collect SciGraph citation data and prepare for D3

      //if citation data available...
      if (item.jsonld.citation) {
        //...per Author:

        // Add author(s) as object(s) directly in citListPerAuthor
        let i = ownCache.citListPerAuthor.length;
        item.jsonld.author.map((creatorItem) => {
          let citEntryAuthor = [];
          // new author object, without any given prototype ("{}")
          citEntryAuthor.push(
            Object.create(
              {},
              {
                name: {
                  value: creatorItem.givenName + " " + creatorItem.familyName,
                },
                authorId: { value: creatorItem.id },
                source: { value: "SciGraph" },
                imports: { value: [] },
              }
            )
          );

          // push the given citated sources to "imports", if this key exist (no pushing to the citated sources itself)
          item.jsonld.citation.map((cit) => {
            citEntryAuthor.map((author) => {
              // importing author
              if (author.imports && cit.author) {
                //importing only the first author name (+ "et. al." if more than 1 author)
                let authName = cit.author[0].given + " " + cit.author[0].family;
                if (cit.author.length > 1) {
                  authName =
                    cit.author[0].given +
                    " " +
                    cit.author[0].family +
                    " et al.";
                }

                author.imports.push(authName);

                // + own entry per citation, if not already existing, and with no 'imports'-key to prevent pushing itself in it (see comment above)
                const citSource = Object.create(
                  {},
                  { name: { value: authName } }
                );
                citEntryAuthor.pushIfNotExist(citSource, (e) => {
                  return e.name === citSource.name;
                });
                // });
              }
            });
          });
          ownCache.citListPerAuthor[i] = citEntryAuthor;

          i++;
        });

        //...per DOI:

        let citEntryAuthor = [];
        // also push authors for metadata
        item.jsonld.author.map((creatorItem) => {
          // new author object, without any given prototype ("{}")
          citEntryAuthor.push(
            Object.create(
              {},
              {
                name: {
                  value: creatorItem.givenName + " " + creatorItem.familyName,
                },
                authorId: { value: creatorItem.id },
              }
            )
          );
        });

        let citEntryDoi = [
          {
            name: item.jsonld.id.replace("sg:pub.", ""),
            title: item.jsonld.name,
            source: "SciGraph",
            author: citEntryAuthor,
            imports: [],
          },
        ];

        // push citation infos per DOI to complete the graph
        item.jsonld.citation.map((cit) => {
          if (cit.DOI) {
            citEntryDoi[0].imports.push(cit.DOI);
            // + own entry per citation
            citEntryDoi.push({
              name: cit.DOI,
              title: cit.title,
              imports: [],
            });
          } else {
            const citDoi = cit.id
              .replace("sg:pub.", "")
              .replace("https://doi.org/", "");
            citEntryDoi[0].imports.push(citDoi);
            // + own entry per citation
            citEntryDoi.push({
              name: citDoi,
              imports: [],
            });
          }
        });

        // Put citation data in the cache (to the current position)
        ownCache.citListPerDoi[actualPos] = citEntryDoi;
      } else {
        // No citation/reference data available for position
        console.log(
          `keine Zitations-/Referenz-Daten verfügbar für Position ${actualPos}`
        );
      }

      // Iterate-Trigger: Cache entry, count up and re-fetch if not yet at the end - otherwise: stop loop and render
      triggerNextItem();
    } else
      console.log(
        "key 'mySource' is missing! value must be 'scigraph' or 'crossref'"
      );
  } else if (loopEnd) {
    // merge all citList-entries for full visualization
    ownCache.fullListPerDoi = [];
    ownCache.fullListPerAuthor = [];

    ownCache.citListPerDoi.map((list) => {
      // pushing dois full list
      list.map((entry) => {
        ownCache.fullListPerDoi.pushIfNotExist(entry, (e) => {
          return e.name === entry.name;
          // missing: imports comparing => if more imports, delete the smaller/empty one
        });
      });
    });

    ownCache.citListPerAuthor.map((author) => {
      // pushing authors to full list
      author.map((entry) => {
        ownCache.fullListPerAuthor.pushIfNotExist(entry, (e) => {
          return e.name === entry.name;
          // missing: imports comparing => if more imports, delete the smaller/empty one
        });
      });
    });

    props.ownCache(ownCache);
    props.loopEnd(true);
    return null;
  } else console.log("state-problems!!");
};

export default ApiFetching;
