import React, { useState, useEffect } from "react";
import "./PubTable.css";

import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

export default function CustomizedTables(props) {
  // get data from App.js

  // set Styles for Material-UI table elements
  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

  function createData(key, value) {
    return { key, value };
  }

  const useStyles = makeStyles({
    wrapper: {
      "overflow-x": "hidden",
    },
    table: {
      minWidth: "auto",
    },
  });

  const classes = useStyles();

  let rows = [];

  // render the metadata table
  return (
    <TableContainer component={Paper} className={classes.wrapper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell colSpan={2}>Metadaten</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.rows
            ? props.rows.map((row) => (
                <StyledTableRow key={row.key}>
                  <StyledTableCell component="th" scope="row">
                    {row.key}
                  </StyledTableCell>
                  <StyledTableCell align="right">{row.value}</StyledTableCell>
                </StyledTableRow>
              ))
            : null}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
