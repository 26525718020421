import * as d3 from "d3";

// changed by Patrick Schmelmer: myRef for React reasons
const d3hierarchical = (d3data, myRef) => {
  //changed by Patrick Schmelmer changed dimensions
  var diameter = 700,
    radius = diameter / 2,
    innerRadius = radius - 150;

  var cluster = d3.cluster().size([360, innerRadius]);

  var line = d3
    .radialLine()
    .curve(d3.curveBundle.beta(0.85))
    .radius(function (d) {
      return d.y;
    })
    .angle(function (d) {
      return (d.x / 180) * Math.PI;
    });
  d3.selectAll("svg").remove();
  var svg = d3
    // changed by Patrick Schmelmer: selecting ref
    .select(myRef.current)
    .append("svg")
    .attr("width", diameter)
    .attr("height", diameter)
    .append("g")
    .attr("transform", "translate(" + radius + "," + radius + ")");

  var link = svg.append("g").selectAll(".link"),
    node = svg.append("g").selectAll(".node");

  var root = packageHierarchy(d3data).sum(function (d) {
    return d.size;
  });

  cluster(root);

  link = link
    .data(packageImports(root.leaves()))
    .enter()
    .append("path")
    .each(function (d) {
      (d.source = d[0]), (d.target = d[d.length - 1]);
    })
    .attr("class", "link")
    .attr("d", line);

  node = node
    .data(root.leaves())
    .enter()
    .append("text")
    .attr("class", "node")
    .attr("dy", "0.31em")
    .attr("transform", function (d) {
      return (
        "rotate(" +
        (d.x - 90) +
        ")translate(" +
        (d.y + 8) +
        ",0)" +
        (d.x < 180 ? "" : "rotate(180)")
      );
    })
    .attr("text-anchor", function (d) {
      return d.x < 180 ? "start" : "end";
    })

    .text(function (d) {
      // changed by Patrick Schmelmer: if title available show this (first x characters if longer) instead of key
      const x = 25;
      if (d.data.title)
        return d.data.title.length > x
          ? d.data.title.substring(0, x - 1) + "..."
          : d.data.title;
      else
        return d.data.key.length > x
          ? d.data.key.substring(0, x - 1) + "..."
          : d.data.key;
    })
    // changed by Patrick Schmelmer: add id=name~doi to be able to identify the source node furthermore
    // --> highlight all list items in graph
    .attr("id", function (d) {
      if (d.data.source) {
        return d.data.name;
      }
    })
    .on("mouseover", mouseovered)
    .on("mouseout", mouseouted);
  // });

  function mouseovered(d) {
    node.each(function (n) {
      n.target = n.source = false;
    });

    link
      .classed("link--target", function (l) {
        if (l.target === d) return (l.source.source = true);
      })
      .classed("link--source", function (l) {
        if (l.source === d) return (l.target.target = true);
      })
      .filter(function (l) {
        return l.target === d || l.source === d;
      })
      .raise();

    node
      .classed("node--target", function (n) {
        return n.target;
      })
      .classed("node--source", function (n) {
        return n.source;
      });
  }

  function mouseouted(d) {
    link.classed("link--target", false).classed("link--source", false);

    node.classed("node--target", false).classed("node--source", false);
  }

  // Lazily construct the package hierarchy from class names.
  function packageHierarchy(classes) {
    var map = {};

    function find(name, data) {
      var node = map[name],
        i;
      if (!node) {
        node = map[name] = data || { name: name, children: [] };
        if (name.length) {
          node.parent = find(name.substring(0, (i = name.lastIndexOf("."))));
          node.parent.children.push(node);

          // // changed by Patrick Schmelmer: node.key commented out because DOIs were truncated
          // node.key = name.substring(i + 1);

          node.key = name; // full name
        }
      }
      return node;
    }

    classes.forEach(function (d) {
      find(d.name, d);
    });

    return d3.hierarchy(map[""]);
  }

  // Return a list of imports for the given array of nodes.
  function packageImports(nodes) {
    var map = {},
      imports = [];

    // Compute a map from name to node.
    nodes.forEach(function (d) {
      map[d.data.name] = d;
    });

    // For each import, construct a link from the source to target node.
    nodes.forEach(function (d) {
      if (d.data.imports)
        d.data.imports.forEach(function (i) {
          imports.push(map[d.data.name].path(map[i]));
        });
    });

    return imports;
  }
};

export default d3hierarchical;
